import '../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-jquery';
import '../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-lightbox';
import '../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-semantic-ui';

import '../node_modules/semantic-ui-css/semantic.css';
import '../node_modules/lightbox2/dist/css/lightbox.min.css';
import '../node_modules/slick-carousel/slick/slick.css';

import '../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/js/app';
import '../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/app';

import '../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/sass/main.scss';
import '../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/scss/style.scss';

import '../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/avatar.png';
import '../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/logo.png';
import '../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/50x50.png';
import '../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/200x200.png';
import '../vendor/sylius/sylius/src/Sylius/Bundle/UiBundle/Resources/private/img/400x300.png';

import '../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/img/homepage-banner.jpg';
import '../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/img/sylius-plus-banner.png';

import './shop/myCustom.css';
